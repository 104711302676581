<template>
  <div class="col-lg-4 mb-lg-2">
    <b-button
      v-if="checkPermission('create design voucher')"
      v-b-modal.form-design-modal
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Tambah Gift Voucher Design"
      @click="cleanUpForm"
    >
      <feather-icon
        icon="PlusCircleIcon"
      />
      Tambah Gift Voucher Design
    </b-button>

    <b-modal
      id="form-design-modal"
      no-close-on-backdrop
      size="lg"
      title="Tambah Gift Voucher Design"
    >
      <div class="form">
        <h5>Gift Voucher Design</h5>
        <div class="row">

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Nama :</label>
              <validation-provider>
                <b-form-input
                  v-model="formPayloadVoucher.name"
                  :state="validationsVoucher.name ? false:null"
                  placeholder="Nama"
                />
                <small
                  v-for="(validation, index) in validationsVoucher.name"
                  :key="`errorName${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Gambar :</label><br>
              <validation-provider>
                <b-form-file
                  placeholder="Pilih file atau letakkan di sini..."
                  drop-placeholder="Drop file here..."
                  :state="validationsVoucher.image ? false:null"
                  @change="changePhoto($event)"
                />
                <small
                  v-for="(validation, index) in validationsVoucher.image"
                  :key="`errorImage${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div
            v-if="imageVoucher"
            class="col-lg-6"
          >
            <div class="form-group">
              <label
                for=""
                class="mb-1"
              >Gambar Sebelumnya:</label><br>
              <b-img
                :src="imageVoucher"
                width="200"
              />
            </div>
          </div>

          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Jumlah Minimal:</label>
              <validation-provider>
                <b-input-group
                  prepend="Rp"
                >
                  <money
                    v-model="formPayloadVoucher.min_amount"
                    placeholder="Jumlah Minimal"
                    class="form-control"
                    v-bind="money"
                  />
                </b-input-group>
                <small
                  v-for="(validation, index) in validationsVoucher.min_amount"
                  :key="`errormin_amount${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>

        </div>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoadingMissions">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button
          variant="success"
          :disabled="isLoadingMissions"
          @click="editId == null ? createItem() : updateItem()"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BSpinner, BFormInput, BInputGroup, BFormFile, BImg,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import { Money } from 'v-money'

export default {
  components: {
    BButton,
    BSpinner,
    ValidationProvider,
    BFormInput,
    BInputGroup,
    Money,
    BFormFile,
    BImg,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    formPayload: {
      type: '',
    },
    isLoading: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
    createItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    validations: {
      type: '',
    },
    imageVoucher: {
      type: String,
    },
    cleanUpForm: {
      type: Function,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoadingMissions: false,
      formPayloadVoucher: {
        name: '',
        minimum_transaction_total: 0,
        minimum_transaction_time: 0,
        coupon_reward_uuid: '',
        must_order_with_talenta_jagoan: 1,
      },
      validationsVoucher: '',
      money: {
        decimal: '',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false,
      },
      couponsMission: [],
    }
  },
  watch: {
    formPayload: {
      handler(value) {
        this.formPayloadVoucher = value
        if (this.editId == null) {
          this.formPayloadVoucher.image = value.image
        }
      },
      deep: true,
    },
    isLoading(value) {
      this.isLoadingMissions = value
    },
    validations(value) {
      this.validationsVoucher = value
    },
    formPayloadVoucher: {
      handler(value) {
        this.$emit('payload', value)
      },
      deep: true,
    },
  },
  methods: {
    changePhoto(event) {
      // eslint-disable-next-line prefer-destructuring
      this.formPayloadVoucher.image = event.target.files[0]
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
